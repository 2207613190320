import * as React from "react";
import {ReactNode} from "react";
import classNames from "classnames";
import {NavigationViewModel} from "./NavigationViewModel.csharp";
import {NavigationItemViewModel} from "./NavigationItemViewModel.csharp";

interface NavigationState {
    offsetLeft: number,
    navHeight: number,
    scrollPosition: number
}

export class Navigation extends React.Component<NavigationViewModel, NavigationState> {
    private readonly navRef: React.RefObject<any>;

    constructor(props: NavigationViewModel) {
        super(props);

        this.state = {
            offsetLeft: 0,
            navHeight: 0,
            scrollPosition: 0
        };

        this.navRef = React.createRef();
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
        this.handleScroll();
    }

    render(): ReactNode {
        const menuItems = this.props.menu.map((
            item, index) => Navigation.createMenuItem(item, index));
        return <ul ref={this.navRef}
                   className="menu">
            {menuItems}
        </ul>
    }

    private static createMenuItem(menuItem: NavigationItemViewModel, index: number): ReactNode {
        return <li key={index} className="menu__item">
            <a className={classNames("menu__link",
                {"menu__link--selected": menuItem.isSelected}
            )}
               href={menuItem.linkUrl}>
                {menuItem.title}
            </a>
        </li>
    }


    handleScroll(): void {
        const currentScrollPos = window.pageYOffset;
        const headerHeight = this.navRef.current.offsetHeight + 80;
        const body = document.body;

        const isSticky = this.state.scrollPosition > currentScrollPos && currentScrollPos > headerHeight;
        const hiddenNavigation = currentScrollPos > headerHeight;

        this.setState({
            scrollPosition: currentScrollPos
        });

        isSticky ? body.classList.add("header--sticky") : body.classList.remove("header--sticky");
        hiddenNavigation ? body.classList.add("header--hidden") : body.classList.remove("header--hidden");

    };

}
