import React, {useState} from "react";
import { NavigationViewModel } from '../Navigation/NavigationViewModel.csharp';
import {ScrollLock} from "./ScrollLock";

export const Hamburger = (props: NavigationViewModel) => {
    const [isOpen, setIsOpen] = useState(false);
    
    const onToggle = () => {
        document.body.classList.toggle("navigation--open");
        setIsOpen(!isOpen);
    };

    return <ScrollLock isLocked={isOpen}>
        <button onClick={() => onToggle()} className="hamburger">
            <span className="hamburger__content"/>
            <span className="visuallyHidden">
                {isOpen ? props.translations.closeMobileMenu : props.translations.openMobileMenu}
            </span>
        </button>
    </ScrollLock>
};