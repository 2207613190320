import React, {FC} from "react";
import { VacancyPageCardViewModel } from './VacancyPageCardViewModel.csharp';
import classNames from "classnames";

export const VacancyPageCard: FC<VacancyPageCardViewModel> = (props: VacancyPageCardViewModel) => {
    const {company, location, position, workingHours, url} = props;
    
    const generateText = (firstPart: string, secondPart: string): string => secondPart ? `${firstPart}, ${secondPart}` : `${firstPart}`;
    const companyLogo = (company: string) => {
        let companiesWithoutSVGLogos = ['naerbutikken', 'spar'];
        if (companiesWithoutSVGLogos.indexOf(company) === -1) {
            return (
                <span className={classNames("vacancyCard__logo",
                    `vacancyCard__logo--${company}`
                )}>
                    <span className="visuallyHidden">
                        {company}
                    </span>
                </span>
            );
        }
        return <img className='vacancyCard__logo--png' src={`/dist/assets/images/${company}_logo.png`} alt={company}/>
    };
    
    return (
        <a className="vacancyCard" href={url}>
            {companyLogo(company.toLocaleLowerCase())}
            <div className="vacancyCard__title">
                <p>{company && generateText(company.toUpperCase(), location)}</p>
            </div>
            <div className="vacancyCard__subtitle">
                <p>{position}</p>
            </div>
        </a>
    );
};
