import React, {FC} from "react";
import {JobSliderViewModel} from "./JobSliderViewModel.csharp";
import {VacancyPageCard} from "../VacancyPageCard/VacancyPageCard";
import Carousel from "react-multi-carousel";
import classNames from "classnames";


export const JobSlider: FC<JobSliderViewModel> = ({jobs, translations, sliderTranslations, vacanciesListPageUrl}: JobSliderViewModel) => {
    const jobsList = [...jobs]
        .reverse()
        .filter(x => x != null);

    const countOfItemsToShowDesktop = jobs.length > 4 ? 4 : jobs.length;
    const countOfItemsToShowLaptop = jobs.length > 3 ? 3 : jobs.length;

    const responsive = {
        desktop: {
            breakpoint: {max: 3000, min: 1241},
            items: countOfItemsToShowDesktop,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: {max: 1240, min: 768},
            items: countOfItemsToShowLaptop,
            slidesToSlide: 1
        },
        largeMobile: {
            breakpoint: {max: 767, min: 551},
            items: 2,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: {max: 550, min: 0},
            items: 1,
            slidesToSlide: 1
        }
    };

    return jobsList.length ? (
        <div className={classNames("jobSlider",
            {"jobSlider__narrow": jobs.length <= 3}
        )}>
            <Carousel
                ssr={true}
                autoPlay={jobsList.length > 4}
                draggable={false}
                arrows={true}
                autoPlaySpeed={8000}
                infinite={true}
                responsive={responsive}
                containerClass="jobSlider__carousel first-carousel-container contain"
            >
                {jobsList.map((vacancy, id) => (
                    <VacancyPageCard key={id} {...vacancy}/>
                ))}
            </Carousel>
            <div className="jobSlider__showAllVacancies">
                <a href={vacanciesListPageUrl} className="button--primary">
                    {translations.showAllVacancies}
                </a>
            </div>
        </div>
    ) : <></>;
};