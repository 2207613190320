import React, {FC, useState} from "react";
import { Swipeable } from 'react-swipeable';
import { QuoteSliderItem } from '../QuoteSliderItem/QuoteSliderItem'
import { QuoteSliderViewModel } from "./QuoteSliderViewModel.csharp";

export const QuoteSlider: FC<QuoteSliderViewModel> = ({title, quotes, translations}: QuoteSliderViewModel) => {
    
    const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);

    const showNextQuote = (): void => {
        setCurrentQuoteIndex(
            (currentQuoteIndex + 1) % quotes.length
        );
    };

    const showPrevQuote = (): void => {
        let newQuoteIndex: number = currentQuoteIndex === 0
            ? quotes.length - 1
            : currentQuoteIndex - 1;
        
        setCurrentQuoteIndex(
            (newQuoteIndex) % quotes.length
        );
    };
    
    let [ currentQuote, nextQuote ] = [
        quotes[currentQuoteIndex], 
        quotes[(currentQuoteIndex+1)%quotes.length]
    ];
    
    return (
        <div className="quoteSlider">
            <h3 className="quoteSlider__title">
                {title}
            </h3>
            <div className="quoteSlider__controls quoteSlider__controls--prev">
                <button
                    className="quoteSlider__button quoteSlider__button--prev"
                    onClick={() => showPrevQuote()}
                    aria-label={translations.prevItem}
                >
                </button>
            </div>
            <Swipeable
                className="quoteSlider__quotes"
                onSwipedLeft={() => showNextQuote()}
                onSwipedRight={() => showPrevQuote()}
            >
                <QuoteSliderItem
                    contents={currentQuote.contents}
                    author={currentQuote.author}
                    position={currentQuote.position}
                />
                <QuoteSliderItem
                    contents={nextQuote.contents}
                    author={nextQuote.author}
                    position={nextQuote.position}
                />
            </Swipeable>
            <div className="quoteSlider__controls quoteSlider__controls--next">
                <button
                    className="quoteSlider__button quoteSlider__button--next"
                    onClick={() => showNextQuote()}
                    aria-label={translations.nextItem}
                >
                </button>
            </div>
        </div>
    )
};