import * as React from "react";
import * as ReactDOM from "react-dom";
import * as ReactDOMServer from "react-dom/server";
import {ResponsivePicture} from "./responsivePicture/responsivePicture";
import {Hamburger} from "../Partials/Header/Hamburger";
import {Navigation} from "../Partials/Navigation/Navigation";
import {ApplyForm} from "../Pages/Vacancy/ApplyForm";
import {LoginForm} from "../Pages/Vacancy/LoginForm";
import {QuoteSlider} from "../Partials/QuoteSlider/QuoteSlider";
import {QuoteSliderItem} from "../Partials/QuoteSliderItem/QuoteSliderItem";
import {JobSlider} from "../Partials/JobSlider/JobSlider";
import {VacancyPageCard} from "../Partials/VacancyPageCard/VacancyPageCard";
import {CookiesConsent} from "../Partials/CookiesConsent/CookiesConsent";

declare var global: any;

const Components: any = {
    "ResponsivePicture": ResponsivePicture,
    "Hamburger": Hamburger,
    "Navigation": Navigation,
    "ApplyForm": ApplyForm,
    "LoginForm": LoginForm,
    "QuoteSlider": QuoteSlider,
    "QuoteSliderItem": QuoteSliderItem,
    "JobSlider": JobSlider,
    "VacancyPageCard": VacancyPageCard,
    "CookiesConsent": CookiesConsent
};

// register components as global required by ReactJS.NET
for (const component in Components) {
    global[component] = Components[component];
}

global.React = React;
global.ReactDOM = ReactDOM;
global.ReactDOMServer = ReactDOMServer;