import * as React from 'react';

interface ScrollLockProps {
    isLocked: boolean;
}

interface ScrollLockState {
    scrollPosition?: number;
}

export class ScrollLock extends React.Component<ScrollLockProps, ScrollLockState> {
    constructor(props: ScrollLockProps) {
        super(props);
        this.state = {
            scrollPosition: undefined,
        };

        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidUpdate(prevProps: ScrollLockProps): void {
        this.handleScroll(prevProps.isLocked, this.props.isLocked);
    }

    componentDidMount() {
        window.addEventListener("resize",
            () => this.handleScroll(true, false));
    }

    private handleScroll(prevIsLocked: boolean, nextIsLocked: boolean) {
        const isLockedChanged = prevIsLocked !== nextIsLocked;
        if (isLockedChanged) {

            const body = document.body;
            const scrollY = window.scrollY || window.pageYOffset;

            if (nextIsLocked) {
                body.style.overflow = 'hidden';
                body.style.top = `-${scrollY}px`;
            } else if (this.state.scrollPosition !== undefined) {
                body.style.overflow = 'unset';
                body.style.top = '';
                window.scrollTo({
                    top: this.state.scrollPosition,
                    behavior: 'auto'
                });
            }

            this.setState({scrollPosition: nextIsLocked ? scrollY : undefined});
        }
    }

    render() {
        return <>{this.props.children}</>;
    }
}