import React, {FC} from 'react';
import {QuoteSliderItemViewModel} from "./QuoteSliderItemViewModel.csharp"


export const QuoteSliderItem: FC<QuoteSliderItemViewModel> = ({contents, author, position} : QuoteSliderItemViewModel) => (
    <div className="quoteSliderItem">
        <div className="quoteSliderItem__contents" dangerouslySetInnerHTML={{ __html: contents }}/>
        <p className="quoteSliderItem__author">
            {author}
            {position && (
            <span className="quoteSliderItem__position">
                {position}
            </span>
            )}
        </p>
    </div>
);