import React, {useState} from "react";
import ReactModal from 'react-modal';
import {ScrollLock} from "../../Partials/Header/ScrollLock";

export const ApplyForm = (props: { applyId: string, buttonText: string, openModalText: string, closeModalText: string }) => {
    const [isOpen, setIsOpen] = useState(false);

    const onToggle = () => {
        setIsOpen(!isOpen);
    };
    
    return <ScrollLock isLocked={isOpen}>
        <button onClick={() => onToggle()} className="button--primary">
            {props.buttonText}
            <span className="visuallyHidden">
                {isOpen ? props.closeModalText : props.openModalText}
            </span>
        </button>
        <ReactModal
            isOpen={isOpen}
            onRequestClose={onToggle}>
            <button onClick={() => onToggle()} className="button--close">
                <span className="visuallyHidden">
                    {isOpen ? props.closeModalText : props.openModalText}
                </span>
            </button>
            <div className="vacancyPage__applyForm">
                <iframe className="" title="ReachMee Rekruttering ekstern nettside"
                        width="100%" height="3000" frameBorder="0"
                        src={`https://web103.reachmee.com/ext/I011/1081/apply?job_id=${props.applyId}&site=10&validator=1dec50cb85f2be5651dd5988a55725b3&lang=NO`}>
                </iframe>
            </div>
        </ReactModal>
    </ScrollLock>
};