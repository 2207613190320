import React, {useState} from "react";
import ReactModal from 'react-modal';
import {ScrollLock} from "../../Partials/Header/ScrollLock";

export const LoginForm = (props: { loginText: string, linkText: string, openModalText: string, closeModalText: string }) => {
    const [isOpen, setIsOpen] = useState(false);

    const onToggle = () => {
        setIsOpen(!isOpen);
    };

    return <ScrollLock isLocked={isOpen}>
        <p>{props.loginText}: <br/><a href="#" onClick={() => onToggle()}>{props.linkText}</a></p>
        <ReactModal
            isOpen={isOpen}
            onRequestClose={onToggle}>
            <button onClick={() => onToggle()} className="button--close">
                <span className="visuallyHidden">
                    {isOpen ? props.closeModalText : props.openModalText}
                </span>
            </button>
            <div className="vacancyPage__applyForm">
                <iframe width="100%" height="4000" frameBorder="0"
                        src="https://web103.reachmee.com/ext/I011/1081/profile?site=10&validator=1dec50cb85f2be5651dd5988a55725b3&lang=NO">
                </iframe>
            </div>
        </ReactModal>
    </ScrollLock>
};