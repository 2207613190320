import * as React from "react";
import * as Cookies from 'js-cookie';
import {CookiesConsentProps} from "./CookiesConsentProps.csharp";

export class CookiesConsent extends React.Component<CookiesConsentProps, {isClosed: boolean}> {
    private static readonly COOKIE_NAME = "cookiesconsent";
    private static readonly COOKIE_VALUE = "closed";

    constructor(props: CookiesConsentProps){
        super(props);

        this.state = {
            isClosed: Cookies.get(CookiesConsent.COOKIE_NAME) === CookiesConsent.COOKIE_VALUE
        };

        this.close = this.close.bind(this);
    }

    render() {

        if (this.state.isClosed || !this.props.model.message)
            return <></>;

        return <div className="CookiesConsent">
            <div className="wrapper">
                <div className="CookiesConsent__message">
                    <p>{this.props.model.message} <a
                        href={this.props.model.cookiePolicyUrl}>{this.props.model.cookiePolicyLinkText}</a></p>
                </div>
                <button onClick={this.close} className="CookiesConsent__closeButton">
                    <div className="visuallyHidden">props.closeText</div>
                </button>
            </div>
        </div>
    }

    private close() {
        let expirationDate = new Date;
        expirationDate.setFullYear(expirationDate.getFullYear() + 1);
        Cookies.set(CookiesConsent.COOKIE_NAME, CookiesConsent.COOKIE_VALUE,{
            expires: expirationDate
        });
        this.setState({isClosed: true});
    }
}